:root {
  --dark-primary: #027be3;
  --dark-secondary: #26a69a;
  --dark-accent: #9c27b0;
  --dark-color: #1d1d1d;
  --positive-color: #21ba45;
  --negative-color: #c10015;
  --info-color: #31ccec;
  --warning-color: #fec608;
  --primary-color: #6ac0af;
  --light-color: #f1f5f9;
}

.f_input {
  height: 50px;
  width: 100%;
  border-radius: 30px;
  padding: 0px 16px;
  margin: 10px 0px;
  background-color: white !important;
  border: 1px solid #999999;
  font-size: 14px;
  color: #999999;
  &:focus {
    outline: none;
    border: 1px solid var(--warning-color);
  }
}

.f_btn_warning {
  height: 50px;
  padding: 0px 16px;
  margin: 10px 0px;
  background-color: var(--warning-color);
  color: rgb(0, 0, 0);
  font-size: 14px;
  border-radius: 0px;
  border: none;
  transition: background-color 0.5s;
  &:hover {
    background-color: darken(#fec608, 9);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: lighten(#fec608, 10);
  }
}

.f_btn_danger {
  height: 50px;
  padding: 0px 16px;
  margin: 10px 0px;
  background-color: var(--negative-color);
  color: white;
  font-size: 14px;
  border-radius: 0px;
  border: none;
  transition: background-color 0.5s;
  &:hover {
    background-color: darken(#c10015, 9);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: lighten(#c10015, 10);
  }
}
.f_btn_success {
  height: 50px;
  padding: 0px 16px;
  margin: 10px 0px;
  background-color: var(--positive-color);
  color: white;
  font-size: 14px;
  border-radius: 0px;
  border: none;
  transition: background-color 0.5s;
  &:hover {
    background-color: darken(#00c123, 9);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: lighten(#00c123, 10);
  }
}

.f_text_primary {
  align-self: center;
  color: var(--primary-color);
}

.f_text_danger {
  align-self: center;
  color: var(--negative-color);
}

.f_text_success {
  align-self: center;
  color: var(--positive-color);
}

.f_loader {
  border-top: 6px solid #3498db;
  border-radius: 50%;
  display: inline-block;
  width: 37px;
  height: 37px;
  animation: spin 0.6s linear infinite;
  align-self: center;
  margin: 0 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
